html {
    font-family: Arial,Helvetica,sans-serif, "Trebuchet MS", Tahoma, Georgia, "Times New Roman", Times;
    font-size: 100%;
}
html, body {
    height: auto !important; 
    height: 100%; 
    min-height: 100%;
}
body {
    margin: 0;
    padding: 0;
    overflow: auto;
    color: #979797;
/*    background-image: url("../img/main_bg.png"); */
    overflow-y: hidden;	
    line-height: 1;
/*    zoom: 80%;
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8); */
}
::-webkit-scrollbar {
    width: 15px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 9px;
}
::-webkit-scrollbar-thumb {
    background: #303F9F; 
    border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
    background: #303F9F; 
  }
.categorii_servicii_tab {
    width: 180px;
    padding: 30px;
    text-align: center;
    cursor: pointer;
}
.servicii_tab {
    padding-top: 18px;
    padding-bottom: 18px;
    text-align: center;
    cursor: pointer;
}
.servicii_selectate_tab {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    cursor: pointer;
}
.cautare_client {
    text-align: center;
}
.cautare_client_text {
    width: 100%;
}
.cautare_client_text_mic {
    margin-right: 6px !important;
}
.cautare_client_rezultate {
    text-align: center;
    margin-top: 27%;
}
.cautare_client_select {
    width: 100%;
}
.cautare_client_select label {
/*    font-size:1rem !important;
    padding-top:9px !important; */
}
#tabel_clienti .p-datatable-scrollable-body {
    min-height: 204.9px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
.icon_button {
    margin-left: -15px;
    margin-right: 6px;
}
.buton_cautare {
    margin-top: 8px !important;
    width: 153px !important;
    margin-right: 9px !important;
}
.MuiDialogTitle-root {
    padding-bottom: 3px !important;
}
.icon_button_header {
    margin-left: -15px;
    margin-right: 6px;
    width: 171px;
}
.MuiTabs-root {
    min-height: auto !important;
}
#tabel_servicii .p-datatable-scrollable-body {
    min-height: 408px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
.cautare_preturi_rezultate {
    text-align: center;
    margin-bottom: 27%;
    margin-top: 18%;
}
.paper_padding_15 {
    padding: 15px;
}
.input_reduced {
    height: 30px;
}
.MuiDialog-paperScrollPaper {
    max-height: calc(100% - 45px) !important;
    margin-top: 54px;
}
.button_unselected {
    background-color: blue !important;
}
.button_selected {
    background-color: red !important;
}
#tabel_fise .p-datatable-scrollable-body {
    min-height: 498px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
.MuiOutlinedInput-inputMarginDense {
    padding-top: 9px;
    padding-bottom: 9px;
}
.buton_inchidere_fisa {
    margin-top: 8px !important;
    width: 201px !important;
    margin-right: 9px !important;
}
.icon_status_inchidere_fisa {
    margin-left: 3px;
    margin-right: 3px;
    float: left;
}
.specialitate_inchidere_fisa {
    line-height: 24px;
    font-weight: 570;
}
.buton_fise_deschise {
    margin-top: 8px !important;
    width: 201px !important;
    margin-right :9px !important;
}
#fisa_auto_pdf .p-toggleable-content {
    height: 90%;
}
#fisa_auto_pdf .p-fieldset-content {
    height: 100%;
}
#factura_pdf .p-toggleable-content {
    height: 90%;
}
#factura_pdf .p-fieldset-content {
    height: 100%;
}
#fisa_paza_pdf .p-toggleable-content {
    height: 96%;
}
#fisa_paza_pdf .p-fieldset-content {
    height: 96%;
}
#fisa_port-arma_pdf .p-toggleable-content {
    height: 96%;
}
#fisa_port-arma_pdf .p-fieldset-content {
    height: 96%;
}
#cos_servicii_facturare .MuiAutocomplete-input {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
#cos_servicii_facturare .MuiInputLabel-outlined {
    line-height: 0.3 !important;
}
#cos_servicii_servicii .MuiAutocomplete-input {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
#cos_servicii_servicii .MuiInputLabel-outlined {
    line-height: 0.3 !important;
}
#tabel_fise_asteptare .p-datatable-scrollable-body {
    min-height: 498px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
.buton_fise_deschise_1 {
    margin-top: 8px !important;
    width: 150px !important;
    margin-right: 9px !important;
}
#tabel_beneficiari .p-datatable-scrollable-body {
    height: 543px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#div_preturi_beneficiar {
    height: 588px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    width: 100%;
    margin: 0 auto;
}
#lista_items_1 .p-datatable-scrollable-body {
    min-height: 351px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#registru_consultatii_pdf .p-fieldset-content {
    height: 570px !important;
}
#acord_gdpr_pdf .p-fieldset-content {
    height: 570px !important;
}
#aviz_sc_pdf .p-fieldset-content {
    height: 570px !important;
}
#raport_sc_pdf .p-fieldset-content {
    height: 570px !important;
}
#comanda_analize_pdf .p-toggleable-content {
    height: 96%;
}
#comanda_analize_pdf .p-fieldset-content {
    height: 99%;
}
label.MuiFormControlLabel-root {
    margin-right: 9px;
}
.MuiInputBase-root {
/*    padding: 0px !important;
    padding-right:12px !important; */
}
.MuiInputBase-input {
    padding-left: 12px !important;
    padding-right: 0px !important;
}
#tabel_dosar_medical .p-datatable-scrollable-body {
    min-height: 579px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#detalii_servicii_div .p-fieldset-content {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}
#cos_servicii_servicii .p-fieldset-content {
    height: 570px;
    overflow-y: auto;
    overflow-x: hidden;
}
.MuiTypography-paragraph {
    margin-bottom: 6px;
}
.MuiOutlinedInput-inputMultiline {
    padding: 12px !important;
}
#pdf_out .p-toggleable-content {
    height: 96%;
}
#pdf_out .p-fieldset-content {
    height: 99%;
}
#full_height_table .p-datatable-scrollable-body {
    min-height: 519px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#mmRisksTable .p-datatable-scrollable-body {
    min-height: 543px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#mmSelectedInvestigationsTable .p-datatable-scrollable-body {
    min-height: 420px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#mmAddInvestigationTable .p-datatable-scrollable-body {
    min-height: 460px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
.p-datatable-scrollable-header-box {
    margin-right: 0px! important;
}
.p-column-filter {
    width:99% !important;
}
.p-fieldset-legend {
    padding: 6px !important;
    border: 0px solid black !important;
    background: inherit !important;
    border-radius: 0px !important;
}
#mmInvestigationsTable .p-datatable-scrollable-body {
    min-height: 558px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#branchesTable .p-datatable-scrollable-body {
    min-height: 186px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#asociatesTable .MuiChip-label {
    width: 90% !important;
    text-align: left
}
#departmentsTable .p-datatable-scrollable-body {
    min-height: 186px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#div_preturi_beneficiar .MuiOutlinedInput-input {
    padding-left: 6px !important;
}
#unbilledServicesTable .p-datatable-scrollable-body {
    min-height: 489px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#mmPackagesInvestigationsTable .p-datatable-scrollable-body {
    min-height: 498px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
#mmPackagesRisksTable .p-datatable-scrollable-body {
    min-height: 498px !important;
    cursor: pointer !important;
    border-left: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
}
